import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import { List, Col, Container, Row } from 'reactstrap'

const IndexPage = () => {
  return (
    <Layout>
      <Container fluid>
        <Helmet title="ID700" />
        <Row noGutters>
          <Col xs={12} sm={{ size: 10, offset: 1 }}>
            <div className="d-sm-none">
              <h1 className="text-center">ID700</h1>
            </div>
            <div className="mt-1 mb-1 d-flex flex-row justify-content-between align-items-center">
              <h1 className="mb-1 d-none d-sm-flex">ID700</h1>
              <a href="/id700#history">History</a>
              <a href="/id700/#features">Features</a>
              <a href="/id700#reviews">Reviews</a>
              <a href="/id700#downloads">Downloads</a>
              <a href="/id700/support">Support</a>
            </div>
          </Col>

          <Col xs={12} sm={{ size: 10, offset: 1 }}>
            <div className="pl-md-3 text-xs-justify">
              <hr />
              <h3 className="font-weight-light">Support and troubleshooting</h3>
              <h4>MacOS</h4>
              <p>
                The following steps can help resolve crashes in ID700. Before
                each step please ensure your DAW is closed. After each step
                reopen your DAW and try running ID700 again.
              </p>
              <h5>Run the standalone application</h5>
              <p>
                Sometimes the AUv3 registration process doesn't complete.
                Running the standalone application can resolve this.
              </p>
              <h5>Install the VST3 wrapper</h5>
              <p>
                Sometimes ID700 crashes due to permission issues in your User
                directory. The <a href="/id700#downloads">VST3 installer</a>{' '}
                runs a script that fixes this. Apple has strong limitations
                around what App Store applications can access in your home
                directory so this script cannot ship as part of the ID700
                installation.
              </p>
              <h5>Reboot</h5>
              <p>
                Sometimes turning it off and on again actually fixes issues with
                AUv3 plugins.
              </p>
              <h4>Contact us</h4>
              <p>
                If ID700 still isn't working then please email{' '}
                <b>info@modo.sc</b> with a description of your problem. Please
                also attach all of the log files below that are present on your
                computer:
              </p>
              <List>
                <li>
                  ~/Library/Containers/sc.modo.instrumentdesigner.macos/Data/Library/Logs/ID700/ID700.log
                </li>
                <li>
                  ~/Library/Containers/sc.modo.instrumentdesigner.macos.macosAUv3/Data/Library/Logs/ID700/ID700.log
                </li>
                <li>~/Library/Logs/ID700 Wrapper/ID700 Wrapper.log</li>
              </List>
              <p>
                For each file name in this list do the following:
                <List>
                  <li>Open Finder</li>
                  <li>Press Shift-Command-G</li>
                  <li>
                    Copy and paste the file name into the resulting window
                  </li>
                  <li>
                    If there's a match, press enter. If not, skip to the next
                    file in the list.
                  </li>
                  <li>
                    Attach the log file from the newly opened Finder window into
                    your email
                  </li>
                </List>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
